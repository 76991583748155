class SupportChecker {
    static get intersectionObserverSupported() {
        return 'IntersectionObserver' in window || 'IntersectionObserverEntry' in window ||
            'intersectionRatio' in window.IntersectionObserverEntry.prototype
    }

    static get resizeObserverSupported() {
        return 'ResizeObserver' in window || 'ResizeObserverEntry' in window ||
            'intersectionRatio' in window.IntersectionObserverEntry.prototype
    }

    static get localStorageSupported() {
        return typeof (Storage) !== 'undefined' || ('localStorage' in window && window['localStorage'] !== null)
    }
}

export default SupportChecker
