import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['radio']

    perform(event) {
        let currentRadio = event.target

        Array.prototype.forEach.call(this.radioTargets, function (element) {
            element.checked = false
        })
        currentRadio.checked = true
    }
}
