import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

    initialize() {
        this.setShowLeavingAlert('true')
    }

    leavingPage(event) {
        if (this.showLeavingAlert()) {
            // do NOT remove this text! It is not shown, but it is required in firefox
            event.returnValue = 'Are you sure?'
            return event.returnValue
        }
    }

    allowFormSubmission(_event) {
        this.setShowLeavingAlert('false')
    }

    setShowLeavingAlert(showAlert) {
        this.data.set("showAlert", showAlert)
    }

    showLeavingAlert() {
        return this.data.get("showAlert") === 'true'
    }
}
