import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['source', 'copyButton']

    connect() {
        if (this._clipboardSupport()) {
            this.element.classList.add('clipboard--supported')
        } else {
            this.copyButtonTarget.style.display = 'none'
        }
    }

    copy() {
        if (this.sourceTarget instanceof HTMLInputElement) {
            navigator.clipboard.writeText(this.sourceTarget.value)
        } else {
            navigator.clipboard.writeText(this.sourceTarget.innerText.trim())
        }
    }

    _clipboardSupport() {
        if (!navigator.clipboard) return false
        try {
            navigator.clipboard.writeText('42')
        } catch (_error) {
            return false
        }
        return true
    }
}
