export function MentionCustomization(editor) {
    // The upcast converter will convert <a class="mention" href="" data-mention-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for('upcast').elementToAttribute({
        view: {
            name: 'a',
            key: 'data-mention',
            classes: 'mention',
            attributes: {
                href: true,
                'data-mention-id': true,
                'data-mention-type': true
            }
        },
        model: {
            key: 'mention',
            value: viewItem => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object, use the toMentionAttribute helper method:
                return editor.plugins.get('Mention').toMentionAttribute(viewItem, {
                    // Add any other properties that you need.
                    link: viewItem.getAttribute('href'),
                    mentionId: viewItem.getAttribute('data-mention-id'),
                    mentionType: viewItem.getAttribute('data-mention-type')
                })
            }
        },
        converterPriority: 'high'
    })

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for('downcast').attributeToElement({
        model: 'mention',
        view: (modelAttributeValue, {writer}) => {
            // Do not convert empty attributes (lack of value means no mention).
            if (!modelAttributeValue) {
                return
            }

            return writer.createAttributeElement('a', {
                class: 'mention',
                'data-mention': modelAttributeValue.id,
                'data-mention-id': modelAttributeValue.mentionId,
                'data-mention-type': modelAttributeValue.mentionType,
                'href': modelAttributeValue.link
            }, {
                // Make mention attribute to be wrapped by other attribute elements.
                priority: 20,
                // Prevent merging mentions together.
                id: modelAttributeValue.uid
            })
        },
        converterPriority: 'high'
    })
}

// <span class="dropdown-item-text ck-button ck-on" id="mention-list-item-id-contents_knowledge_5">
//   <span class="ck__mention-title">
//     AAA WET
//   </span>
//   <span class="text-muted">
//     Wissenseintrag
//   </span>
// </span>
//
export function mentionItemRenderer(item) {
    const itemElement = document.createElement('span')

    itemElement.classList.add('dropdown-item-text')
    itemElement.id = `mention-list-item-id-${item.mentionId}`

    const nameElement = document.createElement('span')
    nameElement.textContent = `${item.name} `
    nameElement.classList.add('ck__mention-title')
    itemElement.appendChild(nameElement)

    const typeElement = document.createElement('span')
    typeElement.classList.add('text-muted')
    typeElement.textContent = item.mentionType
    itemElement.appendChild(typeElement)

    return itemElement
}
