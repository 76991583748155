/**
 * *********************************************************************************************************************
 * ************ WARNING: WHEN MAKING CHANGES HERE - app/helpers/flash_notices_helper.rb:46 (flash_notice_tag) **********
 * *********************************************************************************************************************
 */
export const toast = (message, type) => {
    const options = {
        typeClasses: 'text-body bg-light',
        buttonClasses: 'btn-close-white',
        timeout: 5000,
        autohide: 'true'
    }

    if (type === 'notice') {
        options.typeClasses = 'text-body bg-info'
        options.buttonClasses = 'btn-close-body'
    } else if (type === 'success') {
        options.typeClasses = 'text-white bg-success'
    } else if (type === 'alert') {
        options.typeClasses = 'text-body bg-warning'
        options.buttonClasses = 'btn-close-body'
        options.timeout = 10000
    } else if (type === 'error') {
        options.typeClasses = 'text-white bg-danger'
        options.autohide = 'false'
    }

    document.getElementById('flashNotices').insertAdjacentHTML('beforeend', `<div class="toast align-items-center ${options.typeClasses} border-0" role="alert" aria-live="assertive" aria-atomic="true" data-controller="toast" data-bs-delay="${options.timeout}" data-bs-autohide="${options.autohide}"><div class="d-flex"><div class="toast-body">${message}</div><button name="button" type="button" class="btn-close ${options.buttonClasses} me-2 m-auto" aria-label="Schließen" data-bs-dismiss="toast"></button></div></div>`)
}
