import {Controller} from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
    static values = {
        handleClass: {type: String, default: 'handle'},
        sortableGroup: String
    }

    initialize() {
        this.onEnd = this.onEnd.bind(this)
        this.onMove = this.onMove.bind(this)
    }

    connect() {
        this._sortable = this._initSortable(this.element)
    }

    _initSortable(element) {
        return Sortable.create(element, {
            tutorizeSortable: true,
            handle: `.${this.handleClassValue}`,
            onEnd: this.onEnd,
            onMove: this.onMove,
            group: this.sortableGroupValue,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            emptyInsertThreshold: 5,
            forceFallback: true
        })
    }

    onEnd(event) {
        this.element.dispatchEvent(new CustomEvent('dragDrop.onDrop', {bubbles: true}))

        const draggedElement = event.item
        const url = draggedElement.dataset.moveUrl
        if (!url) return

        let data = new FormData()
        const target = event.to
        const position = event.newIndex.toString()
        data.append('move[new_position]', position)
        if (draggedElement.dataset.id) data.append('move[id]', draggedElement.dataset.id)
        if (target.dataset.parentId) data.append('move[new_parent_id]', target.dataset.parentId)

        Rails.ajax({
            type: 'PATCH', url, data, error: (_response, _statusText, _xhr) => {
                this._revert(draggedElement, event.oldIndex)
            }
        })
    }

    onMove(event) {
        if (event.dragged.getAttribute('data-not-nestable') === 'true') {
            return !!event.to.getAttribute('data-sortable-root-container')
        }
        return true
    }

    _revert(el, oldIndex) {
        let nextSibling = Sortable.utils.getChild(this._sortable.el, oldIndex, {}, false)

        if (nextSibling) {
            this._sortable.el.insertBefore(el, nextSibling)
        } else {
            this._sortable.el.appendChild(el)
        }
    }
}
