import AutocompleteController from 'stimulus-autocomplete'

export default class extends AutocompleteController {
    static targets = ['loader']

    initialize() {
        this._handleAutocompleteLoadStart = this._handleAutocompleteLoadStart.bind(this)
        this._handleAutocompleteLoadEnd = this._handleAutocompleteLoadEnd.bind(this)
    }

    connect() {
        super.element.addEventListener('loadstart', this._handleAutocompleteLoadStart)
        super.element.addEventListener('loadend', this._handleAutocompleteLoadEnd)
        super.connect()
    }

    disconnect() {
        super.element.removeEventListener('loadstart', this._handleAutocompleteLoadStart)
        super.element.removeEventListener('loadend', this._handleAutocompleteLoadEnd)
        super.disconnect()
    }

    _handleAutocompleteLoadStart(_event) {
        if (this.hasLoaderTarget) this.loaderTarget.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    }

    _handleAutocompleteLoadEnd(_event) {
        if (this.hasLoaderTarget) this.loaderTarget.innerHTML = '<i class="fas fa-search"></i>'
    }

    // Override replaceResults method to check for empty results and showing empty lists.
    replaceResults(html) {
        this.resultsTarget.innerHTML = html
        this.identifyOptions()
        if (!!this.options && (html.length > 0)) {
            this.open()
        } else {
            this.close()
        }
    }
}
