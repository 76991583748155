import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['scrollable', 'navTabs']
    static values = {overflowing: String, scroll_range: {type: Number, default: 200}}

    initialize() {
        this.scrolling = false
    }

    connect() {
        this._moveToCurrentTab()
        this._setOverflowingValue()
    }

    scroll(_event) {
        // Wrap event in ticking frames to not fire a ridiculous amount of scroll events.
        if (!this.scrolling) {
            window.requestAnimationFrame(() => {
                this._setOverflowingValue()
                this.scrolling = false
            })
        }
        this.scrolling = true
    }

    moveLeft(_event) {
        this.scrollableTarget.scrollBy({top: 0, left: (0 - this.scrollRangeValue), behavior: 'smooth'})
        this._setOverflowingValue()
    }

    moveRight(_event) {
        this.scrollableTarget.scrollBy({top: 0, left: this.scrollRangeValue, behavior: 'smooth'})
        this._setOverflowingValue()
    }

    _setOverflowingValue() {
        this.overflowingValue = this._determineHorizontalOverflow(this.navTabsTarget, this.scrollableTarget)
    }

    _determineHorizontalOverflow(content, container) {
        const containerMetrics = container.getBoundingClientRect()
        const containerMetricsRight = Math.floor(containerMetrics.right)
        const containerMetricsLeft = Math.floor(containerMetrics.left)
        const contentMetrics = content.getBoundingClientRect()
        const contentMetricsRight = Math.floor(contentMetrics.right)
        const contentMetricsLeft = Math.floor(contentMetrics.left)
        if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
            return 'both'
        } else if (contentMetricsLeft < containerMetricsLeft) {
            return 'left'
        } else if (contentMetricsRight > containerMetricsRight) {
            return 'right'
        } else {
            return 'none'
        }
    }

    _moveToCurrentTab() {
        const currentItem = this.element.querySelector('[aria-current="page"],[aria-selected="true"]')
        if (currentItem) currentItem.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
    }
}
