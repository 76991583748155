import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['outputField', 'inputField']

    initialize() {
        this.sum()
    }

    inputFieldTargetConnected(_target) {
        this.sum()
    }

    inputFieldTargetDisconnected(_target) {
        this.sum()
    }

    sum() {
        let sum = 0
        Array.prototype.forEach.call(this.inputFieldTargets, function (element, _index) {
            sum += Number(element.value)
        })
        this.outputFieldTarget.innerText = sum
    }
}
