import {Controller} from '@hotwired/stimulus'
import {Modal} from 'bootstrap'
import DomParserSupport from '../components/utility/dom_parser_support'
import LoadScripts from '../components/utility/load_scripts'
import {handleUnauthorized} from '../components/utility/handle_unauthorized'

export default class extends Controller {
    static values = {
        size: String, // sm, lg, xl
        backdrop: {type: String, default: 'static'}, // true, false or 'static'
        keyboard: {type: Boolean, default: true},
        focus: {type: Boolean, default: true},
        url: String,
        autoOpen: {type: Boolean, default: false},
        disableFocusTrap: {type: Boolean, default: false}
    }

    initialize() {
        this._handleShown = this._handleShown.bind(this)
        this._handleHide = this._handleHide.bind(this)
    }

    connect() {
        if (this.autoOpenValue === true) this.openModal()
    }

    disconnect() {
        this._uninstallModalHandler()
    }

    openModal(event) {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
        if (this.element.disabled) return

        this.element.disabled = true
        this.element.classList.add('disabled')

        this._modalHolder = document.getElementById('modalHolder')

        let url = this.hasUrlValue ? new URL(this.urlValue) : new URL(this.element.href)
        if (this.hasSizeValue) url.search = new URLSearchParams({size: this.sizeValue}).toString()

        fetch(url, {
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            if (response.status === 401) handleUnauthorized(response)
            if (!response.ok || response.redirected) throw new Error('Network response was not ok')
            return response.text()
        }).then(text => {
            if (DomParserSupport.isSupported) {
                this._modalHolder.appendChild(DomParserSupport.parse(text))
            } else {
                this._modalHolder.insertAdjacentHTML('beforeend', text)
            }
        }).then(() => {
            this._modal = this._modalHolder.lastChild
            const modalDialog = new Modal(this._modal, {
                backdrop: this.backdropValue,
                keyboard: this.keyboardValue,
                focus: this.focusValue
            })
            if (this._modalHolder.querySelector('textarea[data-controller="editor"]') || this.disableFocusTrapValue) {
                this._disableFocusTrap(modalDialog)
            }

            modalDialog.show()
            this._installModalHandler()
        }).then(() =>
            LoadScripts.load(this._modal)
        ).catch(error =>
            console.log(error)
        )
    }

    _installModalHandler() {
        if (this._modal === undefined) return

        this._modal.addEventListener('shown.bs.modal', this._handleShown)
        this._modal.addEventListener('hide.bs.modal', this._handleHide)
    }

    _uninstallModalHandler() {
        if (this._modal === undefined) return

        this._modal.removeEventListener('shown.bs.modal', this._handleShown)
        this._modal.removeEventListener('hide.bs.modal', this._handleHide)
    }

    _handleShown(_event) {
        let focusElement = this._modalHolder.querySelector('[autofocus]')
        if (focusElement) focusElement.focus()
    }

    _handleHide(_event) {
        this.element.disabled = false
        this.element.classList.remove('disabled')
        this._modal.remove()
        if (this._modalHolder !== undefined) {
            this._modalHolder.querySelector('.modal.show')?.focus()
        }
    }

    // Bootstrap has no option to disable the focusTrap.
    // Bootstrap only uses the methods "activate" and "deactivate" on the _focustrap object, so we override it with noop
    _disableFocusTrap(modalDialog) {
        modalDialog._focustrap = {
            activate: () => {
            },
            deactivate: () => {
            }
        }
    }
}
