import {Controller} from '@hotwired/stimulus'
import {Dropdown} from 'bootstrap'

export default class extends Controller {
    connect() {
        new Dropdown(this.element, {
            boundary: document.body,
            reference: 'toggle',
            popperConfig(defaultBsPopperConfig) {
                return {...defaultBsPopperConfig, strategy: 'fixed'}
            }
        })
    }
}
