window.printIframe = function printIframe(iframeId) {
    try {
        let iframe = document.getElementById(iframeId)
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            iframe.contentWindow.focus()
            iframe.contentWindow.print()
        } else {
            iframe.contentWindow.document.execCommand('print', false, null)
        }
    } catch (_error) {
        window.print()
    }
}
