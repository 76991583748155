import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['indicator']
    static values = {url: String, alternativeTargetQuery: String}

    connect() {
        if (document.documentElement.hasAttribute('data-turbolinks-preview')) return

        this.load()
        this.element['notification'] = {reload: this.load.bind(this)}
    }

    load() {
        fetch(this.urlValue, {
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'Accept': 'application/json'
            }
        }).then(response => response.json())
            .then(json => this.setIndicator(json))
            .catch(error => console.log(error))
    }

    setIndicator(responseJson) {
        if (responseJson.notifications === true) {
            this.indicatorElements.forEach((target, _) => {
                target.classList.add('navbar_user_notifications__indicator--active')
            })
        } else {
            this.indicatorElements.forEach((target, _) => {
                target.classList.remove('navbar_user_notifications__indicator--active')
            })
        }
    }

    get indicatorElements() {
        let targets = []
        if (this.hasAlternativeTargetQueryValue) {
            targets = Array.from(document.querySelectorAll(this.alternativeTargetQueryValue))
            targets.push(this.indicatorTarget)
            return targets
        } else {
            return [this.indicatorTarget]
        }
    }
}
