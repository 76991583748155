import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this._heartbeatTimeout = setInterval(function () {
            fetch('/heartbeats', {
                method: 'PATCH',
                credentials: 'same-origin',
                headers: {
                    'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                    'Accept': 'application/json'
                }
            }).catch(error => console.log(error))
        }, 300000)
    }

    disconnect() {
        if (this._heartbeatTimeout) clearInterval(this._heartbeatTimeout)
    }
}
