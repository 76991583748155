import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {url: String}
    static targets = ['entries', 'intersection']

    connect() {
        this.intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this._load()
                }
            })
        })
        this.intersectionObserver.observe(this.intersectionTarget)
    }

    _load() {
        if (this.urlValue.length === 0) {
            while (this.intersectionTarget.firstChild) this.intersectionTarget.removeChild(this.intersectionTarget.firstChild)
            return
        }

        fetch(this.urlValue, {
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`
            }
        }).then(response => {
            if (!response.ok || response.redirected) throw new Error('Network response was not ok')
            return response.json()
        }).then(json => {
            this.entriesTarget.insertAdjacentHTML('afterbegin', json.entries)
            this.urlValue = json.url
        }).catch(error => {
            console.log(error)
        })
    }
}
