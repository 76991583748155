import {toast} from '../utility/toast'
import {handleUnauthorized} from '../utility/handle_unauthorized'

class TutorizeUploadAdapter {
    constructor(loader, url) {
        this.loader = loader
        this.url = url
    }

    upload() {
        return this.loader.file
            .then(async file => {
                const data = new FormData()
                data.append('media_file[media]', file)

                const response = await fetch(this.url, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`
                    },
                    method: 'POST',
                    body: data
                })

                if (response.status === 401) {
                    handleUnauthorized(response)
                    return Promise.reject()
                }

                const json = await response.json()

                if (response.ok) {
                    return {urls: {['default']: json.download_url}}
                } else {
                    if (json?.error) {
                        toast(json.error[json.error.length - 1], 'alert')
                        return Promise.reject()
                    }
                }
            })
    }

    abort() {
    }
}

export function TutorizeUploadAdapterPlugin(editor, url) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        // Configure the URL to the upload script in your back-end here!
        return new TutorizeUploadAdapter(loader, url)
    }
}
