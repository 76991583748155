import {Controller} from '@hotwired/stimulus'
import DomParserSupport from '../components/utility/dom_parser_support'
import SupportChecker from '../components/utility/support_checker'
import LoadScripts from '../components/utility/load_scripts'
import {handleUnauthorized} from '../components/utility/handle_unauthorized'

export default class extends Controller {
    static values = {
        debug: {type: Boolean, default: false},
        url: String,
        autoload: {type: Boolean, default: true},
        alternativeTargetQuery: String,
        refreshInterval: Number,
        spinner: String,
        removeTargetOnFailure: false,
        spinnerLabel: {type: String, default: 'Loading...'}
    }
    static targets = ['target']

    connect() {
        this._log('connected')
        if (this.autoloadValue === true) this.load()

        this.element['fetchHtml'] = {reload: this.reload.bind(this)}
    }

    disconnect() {
        this._log('disconnected')
        this._stopRefreshing()
        this.intersectionObserver && this.intersectionObserver.disconnect()
    }

    load(_event) {
        SupportChecker.intersectionObserverSupported ? this._lazyLoad() : this._load()
    }

    reload() {
        this._log('reload called')
        this.target.innerHTML = this.spinner
        this._fetch()
    }

    _load() {
        this._log('load called')
        this._fetch()
        if (this.hasRefreshIntervalValue) this._startRefreshing()
    }

    _fetch() {
        this._log('fetch called')
        fetch(this.urlValue, {
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            if (response.status === 401) handleUnauthorized(response)
            if (!response.ok || response.redirected) throw new Error('Network response was not ok')
            if (response.status === 201) this._stopRefreshing()
            return response.text()
        }).then(text => {
            if (text.length === 0) return
            if (DomParserSupport.isSupported) {
                while (this.target.firstChild) this.target.removeChild(this.target.firstChild)
                this.target.appendChild(DomParserSupport.parse(text))
            } else {
                this.target.innerHTML = text
            }
        }).then(() => {
            LoadScripts.load(this.target)
        }).catch(error => {
            this._handleError()
            console.log(error)
        })
    }

    _handleError() {
        this._stopRefreshing()
        if (this.removeTargetOnFailureValue) {
            this.target.remove()
        } else {
            this.target.innerText = 'Error'
        }
    }

    _startRefreshing() {
        this._log('startRefreshing called')
        this.refreshTimer = setInterval(() => {
            this._fetch()
        }, this.refreshIntervalValue)
    }

    _stopRefreshing() {
        if (this.refreshTimer) clearInterval(this.refreshTimer)
    }

    _lazyLoad() {
        this.intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this._load()
                    observer.unobserve(entry.target)
                }
            })
        })
        this.intersectionObserver.observe(this.target)
    }

    _log(message) {
        if (this.debugValue === true) console.log(`DEBUG fetch-html: ${message}`)
    }

    get spinner() {
        return this.spinnerValue || `<div class="text-center py-4"><div class="spinner-border" role="status"><span class="visually-hidden">${this.spinnerLabelValue}</span></div></div>`
    }

    get target() {
        if (this.hasAlternativeTargetQueryValue) {
            return document.querySelector(this.alternativeTargetQueryValue)
        } else {
            return this.targetTarget
        }
    }
}
