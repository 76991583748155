import {Controller} from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
    static values = {
        suggestions: Array,
        userInput: {type: Boolean, default: true},
        dropdownFooterText: {type: String, default: 'more items. Refine your search.'}
    }

    connect() {
        let dropdownFooterText = this.dropdownFooterTextValue // Template options does not have access to 'this' object.

        new Tagify(this.element, {
            whitelist: this.suggestionsValue,
            userInput: this.userInputValue,
            dropdown: {
                enabled: 0,
                maxItems: 5
            },
            originalInputValueFormat: values => values.map(item => item.value).join(', '),
            templates: {
                dropdownFooter(suggestions) {
                    let additionalSuggestions = suggestions.length - this.settings.dropdown.maxItems

                    if (additionalSuggestions > 0) {
                        return `<footer data-selector='tagify-suggestions-footer' class="${this.settings.classNames.dropdownFooter}">${additionalSuggestions} ${dropdownFooterText}</footer>`
                    } else {
                        return ''
                    }
                }
            }
        })
    }
}
