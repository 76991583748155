import {Controller} from '@hotwired/stimulus'
import SupportChecker from '../components/utility/support_checker'

export default class extends Controller {
    connect() {
        if (!SupportChecker.localStorageSupported) return

        let sidebarCollapsed = JSON.parse(localStorage.getItem('main_sidebar_collapsed'))
        if (sidebarCollapsed === true) {
            let toggleElement = document.getElementById('mainSidebar')
            if (toggleElement === null) return

            toggleElement.classList.add('sidebar--collapsed')
            toggleElement.setAttribute('aria-expanded', 'false')
        }
    }

    toggle(_event) {
        let toggleElement = document.getElementById('mainSidebar')
        if (toggleElement === null) return

        toggleElement.classList.toggle('sidebar--collapsed')

        if (toggleElement.classList.contains('sidebar--collapsed')) {
            toggleElement.setAttribute('aria-expanded', 'false')
            if (SupportChecker.localStorageSupported) localStorage.setItem('main_sidebar_collapsed', 'true')
        } else {
            toggleElement.setAttribute('aria-expanded', 'true')
            if (SupportChecker.localStorageSupported) localStorage.removeItem('main_sidebar_collapsed')
        }
    }
}
