import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    submitForm(event) {
        if (!window._rails_loaded) return
        if (!event.target.form.reportValidity()) return

        if (event.key === 'Enter' && !event.shiftKey) {
            let defaultPrevented = Rails.fire(event.target.form, 'submit')
            // Submit the form when Rails prevented the default behavior. ++Rails.fire++ return false if the form is remote.
            if (defaultPrevented) event.target.form.submit()
            event.preventDefault()
        }
    }
}
