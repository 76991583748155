import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['trigger', 'collapsible']
    static values = {state: {type: String, default: 'open'}, open: String, close: String}

    toggle(_event) {
        if (this.stateValue === 'open') {
            Array.prototype.forEach.call(this.collapsibleTargets, function (element) {
                if (element.getAttribute('aria-expanded') === 'true') element.click()
            })
            this.stateValue = 'closed'
            this.triggerTarget.innerText = this.closeValue
        } else if (this.stateValue === 'closed') {
            Array.prototype.forEach.call(this.collapsibleTargets, function (element) {
                if (element.getAttribute('aria-expanded') === 'false') element.click()
            })
            this.stateValue = 'open'
            this.triggerTarget.innerText = this.openValue
        }
    }
}
