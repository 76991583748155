import {Controller} from '@hotwired/stimulus'
import Readmore from 'readmore-js'
import SupportChecker from '../components/utility/support_checker'

export default class extends Controller {
    static values = {
        moreLink: {type: String, default: 'Read more'},
        lessLink: {type: String, default: 'Close'},
        collapsedHeight: {type: Number, default: 200}
    }

    connect() {
        if (SupportChecker.intersectionObserverSupported) {
            this.intersectionObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this._initialize()
                        observer.unobserve(entry.target)
                    }
                })
            })
            this.intersectionObserver.observe(this.element)
        } else {
            this._initialize()
        }
    }

    disconnect() {
        this.intersectionObserver && this.intersectionObserver.disconnect()
    }

    _initialize() {
        new Readmore(this.element, {
            moreLink: `<a href="#"><small>${this.moreLinkValue}</small></a>`,
            lessLink: `<a href="#"><small>${this.lessLinkValue}</small></a>`,
            collapsedHeight: this.collapsedHeightValue
        })
    }
}
