import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {url: String}

    connect() {
        this.resize = this.resize.bind(this)
    }

    startResize(event) {
        if (!event.target.classList.contains('resizable')) return true

        window.addEventListener('mouseup', this.resize)
    }

    resize() {
        window.removeEventListener('mouseup', this.resize)

        const currentHeight = parseInt(this.element.style.height)
        const newHeight = Math.round(currentHeight / 10) * 10
        this.element.style.height = newHeight + 'px'

        let formData = new FormData()
        formData.append('height', newHeight.toString())
        Rails.ajax({type: 'PATCH', url: this.urlValue, data: formData})
    }
}
