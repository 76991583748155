import {BasePlugin} from '@uppy/core'

import Translator from '@uppy/utils/lib/Translator'
import isDragDropSupported from '@uppy/utils/lib/isDragDropSupported'
import getDroppedFiles from '@uppy/utils/lib/getDroppedFiles'

class TutDragDrop extends BasePlugin {
    static VERSION = '0.1.0'

    constructor(uppy, opts) {
        super(uppy, opts)
        this.id = this.opts.id || 'TutDragDrop'
        this.type = 'acquirer'
        this.title = 'Uppy Drag & Drop for TUTORize'

        const defaultOptions = {
            target: null,
            inputFieldTarget: null,
            inputName: 'files[]',
            tooltipText: null,
            mediaPoolUrl: null
        }

        this.opts = Object.assign({}, defaultOptions, opts)

        this.isDragDropSupported = isDragDropSupported()
        this.removeDragOverClassTimeout = null

        this.i18nInit()

        this.handleDragEnter = this.handleDragEnter.bind(this)
        this.handleDragOver = this.handleDragOver.bind(this)
        this.handleDragLeave = this.handleDragLeave.bind(this)
        this.handleDrop = this.handleDrop.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.addFiles = this.addFiles.bind(this)
    }

    setOptions(newOpts) {
        super.setOptions(newOpts)
    }

    i18nInit() {
        this.translator = new Translator([this.defaultLocale, this.uppy.locale, this.opts.locale])
        this.i18n = this.translator.translate.bind(this.translator)
        this.i18nArray = this.translator.translateArray.bind(this.translator)
    }

    addFiles(files) {
        const descriptors = files.map((file) => ({
            source: this.id,
            name: file.name,
            type: file.type,
            data: file,
            meta: {
                // path of the file relative to the ancestor directory the user selected.
                // e.g. 'docs/Old Prague/airbnb.pdf'
                relativePath: file.relativePath || null
            }
        }))

        try {
            this.uppy.addFiles(descriptors)
        } catch (error) {
            this.uppy.log(error)
        }
    }

    handleDragEnter(event) {
        event.preventDefault()
        event.stopPropagation()

        this.opts.target.classList.add('uppy-DragDrop--dragEnter')
    }

    handleDragOver(event) {
        event.preventDefault()
        event.stopPropagation()

        // 1. Add a small (+) icon on drop
        // (and prevent browsers from interpreting this as files being _moved_ into the browser,
        // https://github.com/transloadit/uppy/issues/1978)
        event.dataTransfer.dropEffect = 'copy'

        clearTimeout(this.removeDragOverClassTimeout)
        // this.setPluginState({isDraggingOver: true})

        this.opts.target.classList.add('uppy-DragDrop--isDraggingOver')
    }

    handleDragLeave(event) {
        event.preventDefault()
        event.stopPropagation()

        clearTimeout(this.removeDragOverClassTimeout)
        // Timeout against flickering, this solution is taken from drag-drop library.
        // Solution with 'pointer-events: none' didn't work across browsers.
        this.removeDragOverClassTimeout = setTimeout(() => {
            // this.setPluginState({isDraggingOver: false})
            this.opts.target.classList.remove('uppy-DragDrop--isDraggingOver')
            this.opts.target.classList.remove('uppy-DragDrop--dragEnter')
        }, 50)
    }

    handleDrop(event) {
        event.preventDefault()
        event.stopPropagation()
        clearTimeout(this.removeDragOverClassTimeout)

        // 2. Remove dragover class
        // this.setPluginState({isDraggingOver: false})

        // 3. Add all dropped files
        this.uppy.log('[TutDragDrop] Files were dropped')
        const logDropError = (error) => {
            this.uppy.log(error, 'error')
        }
        getDroppedFiles(event.dataTransfer, {logDropError}).then((files) => this.addFiles(files))

        this.opts.target.classList.remove('uppy-DragDrop--isDraggingOver')
        this.opts.target.classList.remove('uppy-DragDrop--dragEnter')
    }

    handleInputChange(event) {
        const files = Array.from(event.target.files)
        this.addFiles(files)
        // Clears out the event target input field to not submit the file after initial upload.
        event.target.value = ''
    }

    renderFileInput() {
        const randomId = Date.now()
        const restrictions = this.uppy.opts.restrictions
        let inputText
        let tooltip

        if (this.opts.mediaPoolUrl) {
            inputText = this.i18nArray('dropHereOrMediaPool', {
                mediaPool: `<a href="${this.opts.mediaPoolUrl}" data-controller="modal-link" data-action="click->modal-link#openModal" role="buton">${this.i18n('mediaPool')}</a>`,
                browse: `<label for="${randomId}" tabindex="0" onkeyup="if (event.keyCode === 13) { event.preventDefault(); document.getElementById('${randomId}').click(); }">${this.i18n('browse')}</label>`
            })
        } else {
            inputText = this.i18nArray('dropHereOr', {browse: `<label for="${randomId}" tabindex="0" onkeyup="if (event.keyCode === 13) { event.preventDefault(); document.getElementById('${randomId}').click(); }">${this.i18n('browse')}</label>`})
        }

        if (this.opts.tooltipText) {
            tooltip = `<span class="uppy-DragDrop-inputTextTooltip" data-controller="popover" data-bs-content="${this.opts.tooltipText}"><i class="fas fa-question-circle"></i></span>`
        } else {
            tooltip = ''
        }

        this.opts.inputFieldTarget.innerHTML = `
            <div class="uppy-DragDrop-fileInput">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" class="uppy-DragDrop-icon fa-cloud-upload-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg>
                <span class="uppy-DragDrop-inputText">${inputText.join('')}</span>
                ${tooltip}
                <input hidden="hidden" type="file" name="${this.opts.inputName}" id="${randomId}"
                    tabindex="${-1}" focusable="false" multiple="${restrictions.maxNumberOfFiles !== 1}" 
                    accept="${restrictions.allowedFileTypes}" class="uppy-DragDrop-input">
            </div>
        `
    }

    //
    // render(state) {
    //     const dragDropClass = `
    //         uppy-Root
    //         uppy-u-reset
    //         uppy-DragDrop-container
    //         ${this.isDragDropSupported ? 'uppy-DragDrop--is-dragdrop-supported' : ''}
    //         ${this.getPluginState().isDraggingOver ? 'uppy-DragDrop--isDraggingOver' : ''}
    //        `
    //
    //     return this.renderFileInput()
    // }

    install() {
        // this.setPluginState({isDraggingOver: false})
        // const target = this.opts.target
        // if (target) {
        //     this.mount(target, this)
        // }
        this.opts.target.addEventListener('dragenter', this.handleDragEnter)
        this.opts.target.addEventListener('dragover', this.handleDragOver)
        this.opts.target.addEventListener('dragleave', this.handleDragLeave)
        this.opts.target.addEventListener('drop', this.handleDrop)
        if (this.opts.inputFieldTarget) {
            this.renderFileInput()
            this.opts.inputFieldTarget.querySelector('input[type="file"]')
                .addEventListener('change', this.handleInputChange)
        }
        this.opts.target.classList.add('uppy-DragDrop-container')
        if (this.isDragDropSupported) this.opts.target.classList.add('uppy-DragDrop--is-dragdrop-supported')
    }

    uninstall() {
        // this.unmount()
        this.opts.target.removeEventListener('dragenter', this.handleDragEnter)
        this.opts.target.removeEventListener('dragover', this.handleDragOver)
        this.opts.target.removeEventListener('dragleave', this.handleDragLeave)
        this.opts.target.removeEventListener('drop', this.handleDrop)
        if (this.opts.inputFieldTarget)
            this.opts.inputFieldTarget.querySelector('input[type="file"]')
                .removeEventListener('change', this.handleInputChange)
    }
}

module.exports = TutDragDrop
