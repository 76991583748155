import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    initialize() {
        this.preventLeave = false
        this._setupListeners()
    }

    _setupListeners() {
        ['input', 'change'].forEach(type => {
            this.element.addEventListener(type, () => (this.preventLeave = true))
        })
        this.element.addEventListener('submit', () => (this.preventLeave = false))

        window.addEventListener('beforeunload', event => {
            if (this.preventLeave) {
                // do NOT remove this text! It is not shown, but it is required in firefox
                event.returnValue = 'Are you sure?'
                return event.returnValue
            }
        })
    }
}