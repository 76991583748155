import {Controller} from '@hotwired/stimulus'

import 'mediaelement'
import 'mediaelement/build/lang/de'
import 'mediaelement/build/renderers/vimeo'

export default class extends Controller {
    static targets = ['mediaElement']
    static values = {dashPath: String, hlsPath: String, iconSpritePath: String}

    initialize() {
        mejs.i18n.language(document.documentElement.lang)
    }

    connect() {
        this._sourceType = this.mediaElementTarget.querySelector('source').getAttribute('type')
        this._mediaElementPlayer = new MediaElementPlayer(this.mediaElementTarget, this._createOptionsHashForPlayer())
        // The renderer for html5 streaming elements is not defined and the muted option for that is a set through the
        // parentNode. The parentNode seems to be the streaming player, dash or hls. For all other elements we can mute
        // the media element directly.
        if (typeof this._mediaElementPlayer.media.rendererName === 'undefined') {
            this._mediaElement = this._mediaElementPlayer.media.parentNode
        } else {
            this._mediaElement = this._mediaElementPlayer.media
        }
        this._mediaElement.setMuted(localStorage.getItem('mute') === 'true')
        this._installMediaElementListener()
        this._disableRightClickOnContextMenu()
    }

    disconnect() {
        this._uninstallMediaElementListener()
    }

    _disableRightClickOnContextMenu() {
        Array.prototype.forEach.call(document.querySelectorAll('.mejs__container'), function (element, _index) {
            element.oncontextmenu = function () {
                return false
            }
        })
    }

    _createOptionsHashForPlayer() {
        let startVolume = localStorage.getItem('volume') !== null ? localStorage.getItem('volume') : 0.8
        if (this.mediaElementTarget.nodeName === 'AUDIO') {
            return {
                iconSprite: this.iconSpritePathValue,
                startVolume: startVolume,
            }
        } else if (this._sourceType === 'video/vimeo' || this._sourceType === 'video/youtube') {
            return {
                renderers: ['youtube_iframe', 'vimeo_iframe'],
                features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'],
                iconSprite: this.iconSpritePathValue,
                autoRewind: false,
                startVolume: startVolume,
                youtube: {
                    nocookie: true
                }
            }
        } else if (this.mediaElementTarget.nodeName === 'VIDEO') {
            if (mejs.Features.isiOS) {
                Array.prototype.forEach.call(this.mediaElementTarget.querySelectorAll('source'), function (source, _index) {
                    if (source.src.indexOf('.mpd') !== -1) source.disabled = true
                })
            }
            return {
                renderers: ['native_dash', 'native_hls', 'html5'],
                features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'],
                iconSprite: this.iconSpritePathValue,
                autoRewind: false,
                startVolume: startVolume,
                dash: {path: this.dashPathValue},
                hls: {path: this.hlsPathValue}
            }
        }
    }

    _handleVolumeChange() {
        if (this._mediaElement.options.startVolume !== this._mediaElement.getVolume()) {
            localStorage.setItem('volume', this._mediaElement.getVolume())
        }
        localStorage.setItem('mute', this._mediaElement.muted)
    }

    _handleMediaEnd() {
        let toggleProgressCheckBox = document.getElementById('flexSwitchToggleProgress')
        if (toggleProgressCheckBox && !toggleProgressCheckBox.checked) toggleProgressCheckBox.click()
    }

    _installMediaElementListener() {
        this._mediaElement.addEventListener('volumechange', () => this._handleVolumeChange())
        this._mediaElement.addEventListener('ended', this._handleMediaEnd)
    }

    _uninstallMediaElementListener() {
        this._mediaElement.removeEventListener('volumechange', () => this._handleVolumeChange())
        this._mediaElement.removeEventListener('ended', this._handleMediaEnd)
    }
}
