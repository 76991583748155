import {Controller} from '@hotwired/stimulus'

import SyncPick from 'sync-pick'

export default class extends Controller {
    static values = {
        emptySelectButtonTextOption: String,
        containerOption: String,
        withSearchOption: Boolean,
        withSelectAllButtonOption: Boolean,
        dropdownAlignRightOption: Boolean,
        dropdownButtonClasses: Array,
        searchInputClasses: Array,
        listClasses: Array,
        selectedTextFormatOption: String
    }

    reconnect() {
        this._sync_pick.destroy()
        this.load()
    }

    connect() {
        this.load()
        this.element['syncPick'] = {
            reconnect: this.reconnect.bind(this),
            values: this._sync_pick.selectedValues
        }
    }

    load() {
        let options = this.options
        Object.keys(options).forEach(function (key) {
            if (options[key] === "") delete options[key]
        })

        const elementId = this.element.getAttribute('id')
        this._sync_pick = new SyncPick(Object.assign({}, this.defaultOptions, {id: elementId}, options))
    }

    get options() {
        return {
            buttonClasses: this.hasDropdownButtonClassesValue ? this.dropdownButtonClassesValue : '',
            searchInputClasses: this.hasSearchInputClassesValue ? this.searchInputClassesValue : '',
            listClasses: this.hasListClassesValue ? this.listClassesValue : '',
            emptySelectButtonText: this.emptySelectButtonTextOptionValue,
            container: this.containerOptionValue,
            withSearch: this.hasWithSearchOptionValue ? this.withSearchOptionValue : '',
            withSelectAllButton: this.hasWithSelectAllButtonOptionValue ? this.hasWithSelectAllButtonOptionValue : '',
            dropdownAlignRight: this.hasDropdownAlignRightOptionValue ? this.dropdownAlignRightOptionValue : '',
            selectedTextFormat: this.selectedTextFormatOptionValue,
        }
    }

    get defaultOptions() {
        return {
            language: document.documentElement.lang,
            buttonClasses: ['form-select'],
            buttonDisabledClasses: ['btn-disabled'],
            searchTimeout: 0,
            searchInputClasses: ['form-control'],
            buttonIconClasses: [],
            checkedIconClasses: ['fas', 'fa-fw', 'fa-check'],
            listClasses: [],
            selectedTextFormat: 'count>3',
            selectedTextVariable: '%num%',
            container: false,
            withSearch: false,
            withSelectAllButton: false,
            selectAllButtonClasses: ['btn', 'btn-outline-secondary', 'btn-sm'],
            selectAllButtonGroupClasses: ['btn-group'],
            dropdownAlignRight: false,
            popupWidth: '300px',
            values: null
        }
    }
}
