import {Controller} from '@hotwired/stimulus'
import {Modal} from 'bootstrap'
import SupportChecker from '../components/utility/support_checker'

export default class extends Controller {
    initialize() {
        this._handlePostSuccess = this._handlePostSuccess.bind(this)
        this._handlePostError = this._handlePostError.bind(this)
    }

    connect() {
        this._modal = this.element.closest('.modal')
        this._installAjaxListener()
        this._installResizeObserver()

        this.element['modal'] = {close: this.close.bind(this)}
    }

    disconnect() {
        this._uninstallAjaxListener()
        this._uninstallResizeObserver()
    }

    _installAjaxListener() {
        this.element.addEventListener('ajax:success', this._handlePostSuccess)
        this.element.addEventListener('ajax:error', this._handlePostError)
    }

    close(_event) {
        Modal.getInstance(this._modal)?.hide()
    }

    _uninstallAjaxListener() {
        this.element.removeEventListener('ajax:success', this._handlePostSuccess)
        this.element.removeEventListener('ajax:error', this._handlePostError)
    }

    _installResizeObserver() {
        if (SupportChecker.resizeObserverSupported) this._handleResize()
    }

    _uninstallResizeObserver() {
        this._resizeObserver && this._resizeObserver.disconnect()
    }

    _handlePostSuccess(event) {
        if (this._modal && !event.target.hasAttribute('data-do-not-close-modal-on-submit')) {
            Modal.getInstance(this._modal)?.hide()
        }
    }

    _handlePostError(event) {
        this._log('post error called')
        let [data, status, xhr] = event.detail
        this._log(`post error status -> ${status}`)
        if (data.responseText) this._log(`post error response text -> ${data.responseText}`)
    }

    _handleResize() {
        this._resizeObserver = new ResizeObserver((entries, _observer) => {
            entries.forEach((entry) => {
                Modal.getInstance(this._modal)?.handleUpdate()
            })
        })
        this._resizeObserver.observe(this._modal)
    }

    _log(message) {
        console.debug(`DEBUG modal: ${message}`)
    }
}
