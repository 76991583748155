/*
  Datatable Stimulus Controller v1.0.0

  Values:
    debug (false):
    debounceWait (500): Wait time after clicking a filter or typing in a search input field.
    spinner (<html>): HTML for displaying any kind of spinner Element showing when Datatables is doing something.
    spinnerLabel (Loading...): Visually hidden label for displaying information for the spinner element.
    filterFormSelector (form): Default filter form selector for main form.
    perPageName (per_page): Custom name attribute for per page parameter.
    perPageDefault (10): Default per page size.
    sortName (q[s]): Custom name attribute for sort parameter.
    sessionStorageKey (this.filterFormSelectorValue):
    autoFillUrlEnabled (true): Enables autofill of filter form through URL search params.
    autoFillStorageEnabled (true): Enables autofill of filter form through session storage.

  Special debug UrlSearchParam: add debug_per_page Parameter to the url to override the per_page attribute vor all
  Datatables in the site. After setting this parameter ony you mus override this with the datatable_per_page_dropdown
  element or deleting the session storage element.
  Example for only showing 2 Iterm per page: http://u01.dev.localhost:3000/management/users?debug_per_page=2
 */
import {Controller} from '@hotwired/stimulus'
import {debounce} from 'debounce'

export default class extends Controller {
    static values = {
        debug: {type: Boolean, default: false},
        debounceWait: {type: Number, default: 500},
        spinner: String,
        spinnerLabel: String,
        filterFormSelector: String,
        perPageName: {type: String, default: 'per_page'},
        perPageDefault: {type: Number, default: 10},
        sortName: {type: String, default: 'q[s]'},
        sessionStorageKey: String,
        autoFillUrlEnabled: {type: Boolean, default: true},
        autoFillStorageEnabled: {type: Boolean, default: true}
    }
    static targets = ['tableWrapper', 'filterIndicator', 'filterReset']

    initialize() {
        // Debounce action method, triggered by form elements.
        this.debouncedSubmitFilterForm = debounce(this.submitFilterForm, this.debounceWaitValue)
        this._handlePostError = this._handlePostError.bind(this)
    }

    connect() {
        this._showLoader()
        this._filterForm = this.element.querySelector(this.filterFormSelector)
        this._installAjaxErrorListener()
        this._addPerPageField()
        this._addPageField()
        this._addSortField()
        this._originalFilterFormData = this._getFormData()
        this._setFormData()
        this._saveToSessionStorage()
        this._toggleFilterReset()
        Rails.fire(this._filterForm, 'submit')

        // Stimulus Power Move - For Reference.
        this.element[this.identifier] = {
            submitFilterForm: this.submitFilterForm.bind(this),
            debouncedSubmitFilterForm: this.debouncedSubmitFilterForm.bind(this),
            resetFilterForm: this.resetFilterForm.bind(this),
            reloadFilterForm: this.reloadFilterForm.bind(this)
        }
    }

    disconnect() {
        this._uninstallAjaxErrorListener()
    }

    submitFilterForm(_event) {
        this._log('fire rails form submit')
        this._showLoader()
        this._pageField.value = '' // Reset page field whenever the users uses the form filters.
        this._saveToSessionStorage()
        this._setUrlSearchParams()
        this._toggleFilterReset()
        Rails.fire(this._filterForm, 'submit')
    }

    reloadFilterForm(_event) {
        this._log('reload form')
        this._showLoader()
        Rails.fire(this._filterForm, 'submit')
    }

    resetFilterForm(_event) {
        this._log('reset form')
        this._showLoader()
        this._filterForm.reset()
        // Hidden fields are not reset by form.reset().
        this._pageField.value = ''
        this._sortField.value = ''
        this._saveToSessionStorage()
        this._setUrlSearchParams()
        this._toggleFilterReset()
        Rails.fire(this._filterForm, 'submit')
    }

    selectPerPage(event) {
        this._log('select per page')
        this._showLoader()
        this._perPageField.value = event.currentTarget.dataset.perPage
        this._saveToSessionStorage()
        this._setUrlSearchParams()
    }

    filterTable(event) {
        this._log('filter table')
        this._showLoader()
        let sortParam = ''
        if (this.autoFillUrlEnabledValue && event.currentTarget instanceof HTMLAnchorElement) {
            sortParam = new URL(event.currentTarget.href).searchParams.get(this.sortNameValue)
        }
        this._sortField.value = sortParam
        this._saveToSessionStorage()
        this._setUrlSearchParams()
        this._toggleFilterReset()
    }

    paginate(event) {
        this._log('paginate')
        this._showLoader()
        let perPageParam = ''
        if (this.autoFillUrlEnabledValue) perPageParam = new URL(event.currentTarget.href).searchParams.get('page')
        this._pageField.value = perPageParam === '1' ? '' : perPageParam
        this._saveToSessionStorage()
        this._setUrlSearchParams()
    }

    get spinner() {
        return this.spinnerValue || `<tr><td colspan="42"><div class="text-center py-4"><div class="spinner-border" role="status"><span class="visually-hidden">${this.spinnerLabel}</span></div></div></td></tr>`
    }

    get spinnerLabel() {
        return this.spinnerLabelValue || 'Loading...'
    }

    get filterFormSelector() {
        return this.filterFormSelectorValue || 'form'
    }

    get sessionStorageKey() {
        return this.sessionStorageKeyValue || this.filterFormSelector
    }

    _toggleFilterReset() {
        this._log('toggle filter reset')
        if (!this.hasFilterIndicatorTarget) return

        const original_form_data = Object.assign({}, this._originalFilterFormData)
        const current_form_data = Object.assign({}, this._getFormData())

        // We do not want to compare the per_page value because it should not reset.
        delete original_form_data[this.perPageNameValue]
        delete current_form_data[this.perPageNameValue]

        if (JSON.stringify(original_form_data) === JSON.stringify(current_form_data)) {
            this.filterIndicatorTarget.querySelector('.fa-layers-counter').classList.add('d-none')
            this.filterResetTarget.classList.add('d-none')
        } else {
            this.filterIndicatorTarget.querySelector('.fa-layers-counter').classList.remove('d-none')
            this.filterResetTarget.classList.remove('d-none')
        }
    }

    _setUrlSearchParams() {
        if (!this.autoFillUrlEnabledValue) return false

        const original_form_data = Object.assign({}, this._originalFilterFormData)
        const current_form_data = Object.assign({}, this._getFormData())

        if (JSON.stringify(original_form_data) === JSON.stringify(current_form_data)) {
            window.history.replaceState({}, '', location.pathname)
        } else {
            const searchParamsString = new URLSearchParams(this._getFormData(false)).toString()
            window.history.replaceState({}, '', `${location.pathname}?${searchParamsString}`)
        }
    }

    _showLoader() {
        this._log('show loader spinner')
        let tbody = this.tableWrapperTarget.querySelector('tbody')
        if (tbody !== null) {
            tbody.innerHTML = this.spinner
        } else {
            this.tableWrapperTarget.innerHTML = this.spinner
        }
    }

    _getFormData(asObject = true) {
        const formData = new FormData(this._filterForm)
        let data = []

        if (asObject) {
            for (let key of formData.keys()) {
                if (key !== 'authenticity_token') data.push([key, formData.getAll(key)])
            }
            return Object.fromEntries(data)
        } else {
            for (let pair of formData.entries()) {
                if (pair[0] !== 'authenticity_token') data.push([pair[0], pair[1]])
            }
            return data
        }
    }

    _addPerPageField() {
        this._perPageField = this._filterForm.querySelector(`input[name='${this.perPageNameValue}']`)
        if (!this._perPageField) {
            this._perPageField = document.createElement('input')
            this._perPageField.type = 'hidden'
            this._perPageField.name = this.perPageNameValue
            // Enable special debug for overriding per_page attribute.
            let urlSearchParams = new URLSearchParams(window.location.search)
            if (urlSearchParams.has('debug_per_page')) {
                this._perPageField.value = urlSearchParams.get('debug_per_page')
            } else {
                this._perPageField.value = this.perPageDefaultValue
            }
            this._filterForm.appendChild(this._perPageField)
        }
    }

    _addPageField() {
        this._pageField = this._filterForm.querySelector('input[name="page"]')
        if (!this._pageField) {
            this._pageField = document.createElement('input')
            this._pageField.type = 'hidden'
            this._pageField.name = 'page'
            this._filterForm.appendChild(this._pageField)
        }
    }

    _addSortField() {
        this._sortField = this._filterForm.querySelector(`input[name='${this.sortNameValue}']`)
        if (!this._sortField) {
            this._sortField = document.createElement('input')
            this._sortField.type = 'hidden'
            this._sortField.name = this.sortNameValue
            this._sortField.value = new URLSearchParams(window.location.search).get(this.sortNameValue)
            this._filterForm.appendChild(this._sortField)
        }
    }

    _setFormData() {
        let data = {}
        if (this.autoFillUrlEnabledValue && window.location.search !== '') {
            let urlSearchParams = new URLSearchParams(window.location.search)
            // Run through the parameter because Object.fromEntries(urlSearchParams)
            for (let key of urlSearchParams.keys()) {
                data[key] = urlSearchParams.getAll(key)
            }
        } else if (this.autoFillStorageEnabledValue && this._sessionStorageSupported() && sessionStorage.getItem(this.sessionStorageKey) !== null) {
            data = JSON.parse(sessionStorage.getItem(this.sessionStorageKey))
        } else {
            return false
        }

        Object.entries(data).forEach((entry) => {
            let name = entry[0]
            let value = entry[1]
            let input = null
            if ([this.perPageNameValue, 'page', this.sortNameValue].indexOf(name) >= 0) {
                input = this._filterForm.querySelector(`[name='${name}']`)
            } else {
                input = this._filterForm.querySelector(`[name='${name}']:not([type=hidden])`)
            }
            if (input === null) return false

            if (input.type === 'select-multiple') {
                if (!value) return false
                // TODO: Check if removing empty options is needed. Because of the empty filter option on management
                //       news, removing empty options from array is disabled for now.
                // value = [...value].filter(String) // remove all empty options from multi select filter.
                Array.prototype.forEach.call(input.options, (option, _index) => {
                    if (value.includes(option.value)) option.selected = true
                })
            } else if (input.type === 'checkbox') {
                if (Array.isArray(value)) {
                    value.forEach(inputValue => {
                        const checkbox = this._filterForm.querySelector(`[name='${name}'][value='${inputValue}']:not([type=hidden])`)
                        if (checkbox) checkbox.checked = true
                    })
                } else {
                    input.checked = true
                }
            } else if (input.type === 'radio') {
                const activeRadio = this._filterForm.querySelector(`[name='${name}'][value='${value}']:not([type=hidden])`)
                if (activeRadio) activeRadio.checked = true
            } else {
                input.value = value
            }
        })
    }

    _saveToSessionStorage() {
        if (!this.autoFillStorageEnabledValue) return false

        this._log('save data to session storage')
        if (!this._sessionStorageSupported()) return false

        const data = this._getFormData()
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(data))
    }

    _clearSessionStorage() {
        this._log('clear data from session storage')
        // This is not used. The code only stays here in case we decide to reset the forms completely in the future.
        if (!this._sessionStorageSupported() || sessionStorage.getItem(this.sessionStorageKey) === null) return false

        sessionStorage.removeItem(this.sessionStorageKey)
    }

    _log(message) {
        if (this.debugValue) console.log(`DEBUG datatable: ${message}`)
    }

    _sessionStorageSupported() {
        return typeof (Storage) !== 'undefined'
    }

    _installAjaxErrorListener() {
        this._filterForm.addEventListener('ajax:error', this._handlePostError)
    }

    _uninstallAjaxErrorListener() {
        this._filterForm.removeEventListener('ajax:error', this._handlePostError)
    }

    _handlePostError(event) {
        let [data, status, _xhr] = event.detail
        this._log(`post error status ${status}`)
        this._log(data)
        if (data.responseText) this._log(data.responseText)
        this._toggleFilterReset()
    }
}
