/*
  Assignment Stimulus Controller v1.0.0

  Documentation for options 'delta: true':
  This demonstrates how the js lib handels delta data on adding, removing and changing status of the data arrays.
    + -> added object
    - -> removed object
    ~ -> changes status of object

        +---------+                                       - Added objects will be removed from the removedData holder
        |    +    |                                         when persistent.
        +---------+                                       - Added object wil be added to the addedData holder when
             |                                              not persistent.
             |
       -------------             +-----------------+
     /               \    yes    |                 |
    |    persistent   | -------> | removedData -   |
     \               /           |                 |
       -------------             +-----------------+
             |
             | no
             v
    +-----------------+
    |                 |
    | addedData +     |
    |                 |
    +-----------------+


        +---------+                                       - Removed objects will be removed from the addedData holder
        |    -    |                                         when persistent. This objects will also be added to the
        +---------+                                         removedData holder if not already available.
             |                                            - Removed objects wil be removed from the addedData holder
             |                                              when not persistent.
       -------------             +--------------------+
     /               \    yes    | addedData -        |
    |    persistent   | -------> | removedData +      |
     \               /           | (if not available) |
       -------------             +--------------------+
             |
             | no
             v
    +----------------+
    |                |
    | addedData -    |
    |                |
    +----------------+


        +---------+                                        - Changed objects will be added to the removedData holder
        |    ~    |                                          when persistent. This objects are set with the old status.
        +---------+                                          This objects will also be changed in the addedData holder.
             |                                             - Changed objects will be updated in the addedData holder
             |                                               when not persistent.
       -------------             +--------------------+
     /               \    yes    | addedData ~        |
    |    persistent   | -------> | removedData +      |
     \               /           | (with old data)    |
       -------------             +--------------------+
             |
             | no
             v
    +----------------+
    |                |
    | addedData ~    |
    |                |
    +----------------+
 */
import {Controller} from '@hotwired/stimulus'
import doT from 'dot'
import {debounce} from 'debounce'

export default class extends Controller {
    static values = {
        debug: {type: Boolean, default: false},
        delta: {type: Boolean, default: false},
        data: {type: Array, default: []},
        paginationPerPage: {type: Number, default: 10},
        paginationPageGapAt: {type: Number, default: 2},
        paginationPreviousLabel: {type: String, default: '&#8592 Previous'},
        paginationNextLabel: {type: String, default: 'Next &#8594'},
        paginationPageGap: {type: String, default: '&hellip'},
        emptyTableRowText: {type: String, default: 'No entries available'},
        tableFilterText: {type: String, default: 'Filtered'},
        tableSumText: {type: String, default: 'Total'},
        elementExistsText: {type: String, default: 'Element already exists.'},
        elementAddedText: {type: String, default: 'Element has been successfully added.'}
    }

    static targets = ['tableRowTemplate', 'elementsListTable', 'typeahead', 'feedback', 'tableFilter', 'tableFilterType', 'assignationForm']

    initialize() {
        this._handleAutocompleteChange = this._handleAutocompleteChange.bind(this)
        this._handleAssignationFormAjaxComplete = this._handleAssignationFormAjaxComplete.bind(this)
        this._handleAssignationFormSubmit = this._handleAssignationFormSubmit.bind(this)
        this.handleAssignationFilterInput = debounce(this.handleAssignationFilterInput, 500)
    }

    connect() {
        this._log(`Plugin Assignment for DOM element ${this.element}.`)
        this._data = this.dataValue
        this._originalData = this._data.slice()
        if (this.deltaValue) {
            this._dataDeltaAdded = []
            this._dataDeltaRemoved = []
        }
        this._renderTable(1)
        this._installTypeaheadHandler()
        this._installAssignationFormSubmitHandlers()
        this.element['assignment'] = {triggerTableRenderEvent: this._dispatchTableRenderCompleteEvent.bind(this)}
    }

    disconnect() {
        this._uninstallTypeAheadHandler()
        this._uninstallAssignationFormSubmitHandler()
    }

    _installAssignationFormSubmitHandlers() {
        this._log('Form submit handler installed.')
        this.assignationFormTarget.addEventListener('ajax:complete', this._handleAssignationFormAjaxComplete)
        this.assignationFormTarget.addEventListener('submit', this._handleAssignationFormSubmit)
    }

    _uninstallAssignationFormSubmitHandler() {
        this._log('Form submit handler uninstalled.')
        this.assignationFormTarget.removeEventListener('ajax:complete', this._handleAssignationFormAjaxComplete)
        this.assignationFormTarget.removeEventListener('submit', this._handleAssignationFormSubmit)
    }

    _installTypeaheadHandler() {
        this._log('Typeahead handler installed.')
        this.typeaheadTarget.addEventListener('autocomplete.change', this._handleAutocompleteChange)
    }

    _uninstallTypeAheadHandler() {
        this._log('Typeahead handler uninstalled.')
        this.typeaheadTarget.removeEventListener('autocomplete.change', this._handleAutocompleteChange)
    }

    handleAssignationFilterInput(event) {
        if (event.keyCode === 13) {
            event.preventDefault()
            return false
        }
        let value = event.target.value
        let filterTypeSelectedValue = false
        if (this.hasTableFilterTypeTarget) {
            filterTypeSelectedValue = this.tableFilterTypeTarget.querySelector('option:checked')?.value
        }
        if (value) {
            this._data = this._originalData.filter(function (data_object) {
                if (filterTypeSelectedValue) {
                    return data_object.type === filterTypeSelectedValue && data_object['name'].toLowerCase().indexOf(value.toLowerCase()) >= 0
                } else {
                    return data_object['name'].toLowerCase().indexOf(value.toLowerCase()) >= 0
                }
            })
        } else {
            if (filterTypeSelectedValue) {
                this._data = this._originalData.filter(function (data_object) {
                    return data_object.type === filterTypeSelectedValue
                })
            } else {
                this._data = this._originalData.slice(0)
            }
        }
        this._renderTable(1)
    }

    handleAssignationFilterTypeSelect(event) {
        this._log('AssignationFilterTypeSelect called.')
        let selectedValue = event.target.querySelector('option:checked').value
        let filterInputValue = this.tableFilterTarget.value
        if (selectedValue) {
            this._data = this._originalData.filter(function (data_object) {
                if (filterInputValue) {
                    return data_object.type === selectedValue && data_object['name'].toLowerCase().indexOf(filterInputValue.toLowerCase()) >= 0
                } else {
                    return data_object.type === selectedValue
                }
            })
        } else {
            if (filterInputValue) {
                this._data = this._originalData.filter(function (data_object) {
                    return data_object['name'].toLowerCase().indexOf(filterInputValue.toLowerCase()) >= 0
                })
            } else {
                this._data = this._originalData.slice(0)
            }
        }
        this._renderTable(1)
    }

    _handleAssignationFormAjaxComplete(_event) {
        this._log('AssignationFormAjaxComplete called.')
        if (this.deltaValue) {
            this.element.dispatchEvent(
                new CustomEvent('assignment.assignationFormAjaxComplete', {
                    bubbles: true,
                    detail: {
                        dataDeltaAdded: this._dataDeltaAdded,
                        dataDeltaRemoved: this._dataDeltaRemoved
                    }
                })
            )
        } else {
            this.element.dispatchEvent(
                new CustomEvent('assignment.assignationFormAjaxComplete', {
                    bubbles: true,
                    detail: {
                        originalData: this._originalData
                    }
                })
            )
        }
    }

    _handleAssignationFormSubmit(_event) {
        this._log('AssignationFormSubmit called.')
        if (this.deltaValue) {
            this.element.dispatchEvent(
                new CustomEvent('assignment.assignationFormSubmit', {
                    bubbles: true,
                    detail: {
                        dataDeltaAdded: this._dataDeltaAdded,
                        dataDeltaRemoved: this._dataDeltaRemoved
                    }
                })
            )
        } else {
            this.element.dispatchEvent(
                new CustomEvent('assignment.assignationFormSubmit', {
                    bubbles: true,
                    detail: {
                        originalData: this._originalData
                    }
                })
            )
        }
    }

    handleRemoveElement(event) {
        if (!event.target.classList.contains('remove_element_btn')) return

        this._log('RemoveElement called.')
        event.preventDefault()

        let tableRow = event.target.closest('tr')
        let objectId = parseInt(tableRow.dataset.id)
        let objectType = tableRow.dataset.type
        let dataLength = this._data.length
        let numberOfPages = Math.ceil(dataLength / this.paginationPerPageValue)

        if (this.deltaValue) {
            let isInAddedDeltaData = this._searchData(this._dataDeltaAdded, objectId, objectType).length > 0
            let isInRemoveDeltaData = this._searchData(this._dataDeltaRemoved, objectId, objectType).length > 0
            if (isInAddedDeltaData && isInRemoveDeltaData) {
                this._dataDeltaAdded = this._grepData(this._dataDeltaAdded, objectId, objectType)
            } else if (isInAddedDeltaData) {
                this._dataDeltaAdded = this._grepData(this._dataDeltaAdded, objectId, objectType)
            } else {
                let removedObjects = this._searchData(this._originalData, objectId, objectType)
                let oldDataObject = {...{}, ...removedObjects[0]}
                this._dataDeltaRemoved.unshift(oldDataObject)
            }
        }
        this._originalData = this._grepData(this._originalData, objectId, objectType)
        this._data = this._grepData(this._data, objectId, objectType)
        tableRow.remove()
        let paginationId = `assignation_pagination_${this.elementsListTableTarget.id}`
        let pagination = document.getElementById(paginationId).firstChild
        let atPage = 1
        if (pagination.querySelectorAll('li').length !== 0) {
            let currentPage = pagination.querySelector('.page-item.active > a').rel
            currentPage = parseInt(currentPage)
            if (typeof currentPage !== 'undefined') {
                atPage = Math.max(currentPage, 1)
            }
            if (dataLength - (numberOfPages - 1) * this.paginationPerPageValue === 1) {
                atPage = Math.max(currentPage - 1, 1)
            }
        } else {
            atPage = 1
        }
        this._renderTable(atPage)
    }

    _handleAutocompleteChange(event) {
        this._log('AutocompleteChange called.')
        let selectedElement = event.detail.selected
        let selectedObject = {
            type: selectedElement.dataset.type,
            id: parseInt(selectedElement.dataset.id),
            name: selectedElement.dataset.name,
            assigned_at: '',
            members_size: selectedElement.dataset.membersSize,
            image_url: selectedElement.dataset.imageUrl
        }
        let typeAheadSearchInput = this.typeaheadTarget.querySelector('input[type="search"]')
        let searchResult = this._searchData(this._originalData, selectedObject.id, selectedObject.type)
        if (searchResult.length > 0) {
            typeAheadSearchInput.classList.remove('is-valid')
            typeAheadSearchInput.classList.add('is-invalid')
            this.feedbackTarget.classList.remove('valid-feedback')
            this.feedbackTarget.classList.add('invalid-feedback')
            if (searchResult[0].assigned_at === '') {
                this.feedbackTarget.innerText = `${this.elementExistsTextValue}: ${selectedObject.name}.`
            } else {
                this.feedbackTarget.innerText = `${this.elementExistsTextValue}: ${selectedObject.name} (${searchResult[0].assigned_at}).`
            }
        } else {
            typeAheadSearchInput.classList.remove('is-invalid')
            typeAheadSearchInput.classList.add('is-valid')
            this.feedbackTarget.classList.remove('invalid-feedback')
            this.feedbackTarget.classList.add('valid-feedback')
            this.feedbackTarget.innerText = `${this.elementAddedTextValue}: ${selectedObject.name}.`
            if (this.deltaValue) {
                let addedObjects = this._searchData(this._dataDeltaRemoved, selectedObject.id, selectedObject.type)
                if (addedObjects.length > 0) {
                    this._dataDeltaRemoved = this._grepData(this._dataDeltaRemoved, selectedObject.id, selectedObject.type)
                } else {
                    this._dataDeltaAdded.unshift(selectedObject)
                }
                if (addedObjects.length > 0) selectedObject = addedObjects[0]
            }
            if (this.tableFilterTarget.value) {
                this._originalData.unshift(selectedObject)
                if (selectedObject.name.toLowerCase().indexOf(this.tableFilterTarget.value.toLowerCase()) >= 0) {
                    this._data = this._prepend(selectedObject, this._data)
                    this._renderTable(1, true)
                }
            } else {
                this._originalData.unshift(selectedObject)
                this._data = this._prepend(selectedObject, this._data)
                this._renderTable(1, true)
            }
        }
        typeAheadSearchInput.value = ''
        new Promise(resolve => setTimeout(resolve, 50)).then(() => typeAheadSearchInput.focus())
    }

    _renderTable(atPage, flashFirstRow) {
        this._log(`Render table called at page: ${atPage}.`)
        if (flashFirstRow == null) flashFirstRow = false
        let tableRowTemplate = doT.template(this.tableRowTemplateTarget.innerHTML)
        let startItem = Math.max(atPage - 1, 0) * this.paginationPerPageValue
        let endItem = startItem + this.paginationPerPageValue
        this.elementsListTableTarget.querySelector('tbody').innerHTML =
            this._data.slice(startItem, endItem).map(object => {
                return tableRowTemplate(object)
            }).join('')
        this._checkTableEntries()
        if (flashFirstRow) {
            this.elementsListTableTarget.querySelector('tbody > tr:first-child').classList.add('table_row_flash_green')
        }
        this._renderPagination(atPage)
        this._renderTableFooter()
        this._dispatchTableRenderCompleteEvent()
    }

    _renderTableFooter() {
        this._log('Render table footer called.')
        this.elementsListTableTarget.querySelector('tfoot tr td').innerHTML = `<span class="float-end">${this.tableFilterTextValue}: ${this._data.length}, ${this.tableSumTextValue}: ${this._originalData.length}</span>`
    }

    _checkTableEntries() {
        this._log('Checking table entries.')
        let emptyTableRow = `<tr id="empty_table_row"><td colspan="10" class="align-middle text-center">${this.emptyTableRowTextValue}</td></tr>`
        let elementsListTableBody = this.elementsListTableTarget.querySelector('tbody')
        if (elementsListTableBody.childNodes.length === 0) {
            elementsListTableBody.innerHTML = emptyTableRow
        } else {
            this.element.querySelector('#empty_table_row')?.remove()
        }
    }

    _renderPagination(atPage) {
        this._log(`Render pagination called at page: ${atPage}.`)
        let paginationId = `assignation_pagination_${this.elementsListTableTarget.id}`
        if (document.getElementById(paginationId) === null) {
            this.elementsListTableTarget.insertAdjacentHTML('afterend', `<nav id="${paginationId}" aria-label="pager" class="pagy-bootstrap-nav" role="navigation"><ul class="pagination justify-content-center"></ul></nav>`)
        }
        let pagination = document.getElementById(paginationId).firstChild
        pagination.innerHTML = ''
        let numberOfPages = Math.ceil(this._data.length / this.paginationPerPageValue)
        if (numberOfPages <= 1) return

        let rangeStart = atPage - this.paginationPageGapAtValue
        let rangeEnd = atPage + this.paginationPageGapAtValue
        if (rangeEnd > numberOfPages) {
            rangeEnd = numberOfPages
            rangeStart = numberOfPages - (this.paginationPageGapAtValue * 2)
            rangeStart = rangeStart < 1 ? 1 : rangeStart
        }
        if (rangeStart <= 1) {
            rangeStart = 1
            rangeEnd = Math.min(this.paginationPageGapAtValue * 2 + 1, numberOfPages)
        }
        this._log(`Render Pagination rangeStart: ${rangeStart}.`)
        this._log(`Render Pagination rangeEnd: ${rangeEnd}.`)
        let html = ''
        let i
        if (atPage === 1) {
            html += `<li class="page-item prev disabled"><a href="#" class="page-link">${this.paginationPreviousLabelValue}</a></li>`
        } else {
            html += `<li class="page-item prev"><a href="#" rel="prev" class="page-link" data-action="assignment#handlePaginationClick">${this.paginationPreviousLabelValue}</a></li>`
        }
        if (rangeStart <= 3) {
            i = 1
            while (i < rangeStart) {
                html += `<li class="page-item"><a href="#" rel="${i}" class="page-link" data-action="assignment#handlePaginationClick">${i}</a></li>`
                i++
            }
        } else {
            html += '<li class="page-item"><a href="#" rel="1" class="page-link" data-action="assignment#handlePaginationClick">1</a></li>'
            html += `<li class="page-item disabled gap"><a href="#" class="page-link">${this.paginationPageGapValue}</a></li>`
        }
        i = rangeStart
        while (i <= rangeEnd) {
            html += `<li class="page-item"><a href="#" rel="${i}" class="page-link" data-action="assignment#handlePaginationClick">${i}</a></li>`
            i++
        }
        if (rangeEnd >= numberOfPages - 2) {
            i = rangeEnd + 1
            while (i <= numberOfPages) {
                html += `<li class="page-item"><a href="#" rel="${i}" class="page-link" data-action="assignment#handlePaginationClick">${i}</a></li>`
                i++
            }
        } else {
            html += `<li class="page-item disabled gap"><a href="#" class="page-link">${this.paginationPageGapValue}</a></li>`
            html += `<li class="page-item"><a href="#" rel="${numberOfPages}" class="page-link" data-action="assignment#handlePaginationClick">${numberOfPages}</a></li>`
        }
        if (atPage === numberOfPages) {
            html += `<li class="page-item next disabled"><a href="#" class="page-link">${this.paginationNextLabelValue}</a></li>`
        } else {
            html += `<li class="page-item next"><a href="#" rel="next" class="page-link" data-action="assignment#handlePaginationClick">${this.paginationNextLabelValue}</a></li>`
        }
        pagination.innerHTML = html
        pagination.querySelector(`a[rel="${atPage}"]`).parentElement.classList.add('active')
    }

    _dispatchTableRenderCompleteEvent() {
        if (this.deltaValue) {
            this.element.dispatchEvent(
                new CustomEvent('assignment.tableRenderComplete', {
                    bubbles: true,
                    detail: {
                        elementList: this.elementsListTableTarget,
                        originalData: this._originalData,
                        dataDeltaAdded: this._dataDeltaAdded,
                        dataDeltaRemoved: this._dataDeltaRemoved
                    }
                })
            )
        } else {
            this.element.dispatchEvent(
                new CustomEvent('assignment.tableRenderComplete', {
                    bubbles: true,
                    detail: {
                        elementList: this.elementsListTableTarget,
                        originalData: this._originalData,
                    }
                })
            )
        }
    }

    handlePaginationClick(event) {
        this._log('PaginationClick called.')
        let paginationId = `assignation_pagination_${this.elementsListTableTarget.id}`
        let pagination = document.getElementById(paginationId).firstChild
        let numberOfPages = Math.ceil(this._data.length / this.paginationPerPageValue)
        let atPage = 1
        let currentPage = event.target.rel
        if (currentPage === 'prev') {
            this._log('Pagination pre event called.')
            currentPage = pagination.querySelector('.page-item.active > a').rel
            currentPage = parseInt(currentPage)
            if (typeof currentPage !== 'undefined') {
                atPage = Math.max(currentPage - 1, 1)
            }
        } else if (currentPage === 'next') {
            this._log('Pagination next event called.')
            currentPage = pagination.querySelector('.page-item.active > a').rel
            currentPage = parseInt(currentPage)
            this._log(`current page: ${currentPage}`)
            if (typeof currentPage === 'undefined') {
                atPage = numberOfPages
            } else {
                atPage = Math.min(currentPage + 1, numberOfPages)
            }
        } else {
            this._log(`Pagination number event called: ${currentPage}`)
            atPage = parseInt(currentPage)
        }
        this._renderTable(atPage)
    }

    _grepData(data, objectIdToRemove, objectTypeToRemove) {
        return data.filter(function (data_object) {
            return !(data_object.id === objectIdToRemove && data_object.type === objectTypeToRemove)
        })
    }

    _searchData(data, objectIdToSearch, objectTypeToSearch) {
        return data.filter(function (data_object) {
            return data_object.id === objectIdToSearch && data_object.type === objectTypeToSearch
        })
    }

    _prepend(value, array) {
        let newArray = array.slice()
        newArray.unshift(value)
        return newArray
    }

    _log(message) {
        if (this.debugValue === true) console.log(`DEBUG assignment: ${message}`)
    }
}
