import {Controller} from '@hotwired/stimulus'
import {Popover} from 'bootstrap'

export default class extends Controller {
    static values = {
        remoteUrl: String,
        trigger: 'click hover focus' // Value can be click, hover, focus, manual or multiple values at once.
    }

    initialize() {
        this._handlePopoverShown = this._handlePopoverShown.bind(this)
    }

    connect() {
        this._popover = new Popover(this.element, {
            trigger: this.triggerValue,
            html: true
        })
        if (this.hasRemoteUrlValue) this._installPopoverShownListener()
    }

    _installPopoverShownListener() {
        this.element.addEventListener('shown.bs.popover', this._handlePopoverShown)
    }

    _handlePopoverShown(_event) {
        fetch(this.remoteUrlValue, {
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            if (!response.ok || response.redirected) throw new Error('Network response was not ok')
            return response.text()
        }).then(text => {
            this._popover.tip.querySelector('.popover-body').innerHTML = text
            this._popover.update()
        }).catch(error => console.log(error))
    }
}
