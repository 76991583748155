import {Controller} from '@hotwired/stimulus'
import {debounce} from 'debounce'

export default class extends Controller {
    initialize() {
        this.save = this.save.bind(this)
    }

    connect() {
        this.save = debounce(this.save, 50)
    }

    save() {
        if (!window._rails_loaded) return
        if (!this.element.reportValidity()) return

        let defaultPrevented = Rails.fire(this.element, 'submit')
        // Submit the form when Rails prevented the default behavior. ++Rails.fire++ return false if the form is remote.
        if (defaultPrevented) this.element.submit()
    }
}
