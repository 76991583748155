import {toast} from './toast'

export const handleUnauthorized = response => {
    const contentType = response.headers.get('content-type')

    if (contentType.includes('application/json')) {
        response.json().then(json => (toast(json.value, 'error')))
    } else if (response.body) {
        response.text().then(text => (toast(text, 'error')))
    }
}
