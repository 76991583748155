import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['hour', 'minute', 'second']
    static values = {minutes: Number}

    initialize() {
        this.countdownTimer = this.countdownTimer.bind(this)

        const date = new Date()
        this.initialTime = date.setMinutes(date.getMinutes() + this.minutesValue)
    }

    connect() {
        this.countdownTimer()
        this._timeout = setInterval(this.countdownTimer, 1000)
    }

    disconnect() {
        if (this._timeout) clearInterval(this._timeout)
    }

    countdownTimer() {
        const date = new Date()
        date.setMinutes(date.getMinutes() + this.minutesValue)

        const difference = +this.initialTime - +new Date()

        if (difference > 0) {
            const parts = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            }

            this.hourTarget.innerText = parts['hours']
            this.minuteTarget.innerText = parts['minutes']
            this.secondTarget.innerText = parts['seconds']
        } else {
            if (this._timeout) clearInterval(this._timeout)
            this._submitClosestForm()
        }
    }

    _submitClosestForm() {
        const form = this.element.closest('form')
        Rails.fire(form, 'submit')

        if (form.dataset.remote !== 'true') form.submit()
    }
}
