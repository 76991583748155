/*
  Checkbox to Form Stimulus Controller v1.0.0

  This controller is able to listen on click events from a checkbox input and put this information to hidden fields
  that are put on a given outputForm Target.

*/

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['checkbox', 'outputForm']
    static values = {outputFieldName: String}

    checkboxTargetConnected(checkbox) {
        Array.prototype.forEach.call(this.outputFormTarget.querySelectorAll('input[type="hidden"]'), function (element) {
            if (element.value === checkbox.value) {
                checkbox.checked = true
            }
        })
    }

    perform(event) {
        let currentCheckboxValue = event.target.value
        let existingCheckbox = document.getElementById(`${this.outputFieldNameValue}_${currentCheckboxValue}`)

        if (existingCheckbox === null && event.target.checked === true) {
            let newHiddenField = document.createElement('input')
            newHiddenField.type = 'hidden'
            newHiddenField.id = `${this.outputFieldNameValue}_${currentCheckboxValue}`
            newHiddenField.name = this.outputFieldNameValue
            newHiddenField.value = currentCheckboxValue
            this.outputFormTarget.appendChild(newHiddenField)
        } else {
            existingCheckbox.remove()
        }
    }
}
