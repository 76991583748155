class LoadScripts {
    static load(domElement) {
        domElement.querySelectorAll('script').forEach((content) => {
            const script = document.createElement('script')
            script.innerHTML = content.innerHTML
            document.head.appendChild(script).parentNode.removeChild(script)
        })
    }
}

export default LoadScripts