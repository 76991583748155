import {Controller} from '@hotwired/stimulus'

import 'mediaelement'
import 'mediaelement/build/lang/de'
import 'mediaelement/build/renderers/vimeo'

export default class extends Controller {
    static values = {dashPath: String, hlsPath: String, iconSpritePath: String}

    initialize() {
        mejs.i18n.language(document.documentElement.lang)
    }

    connect() {
        const elements = this.element.querySelectorAll('oembed')
        elements.forEach(e => this._loadMediaFile(e))
    }

    _loadMediaFile(oembed) {
        if (oembed.hasAttribute('url')) {
            this._loadYoutubeVimeo(oembed)
        } else if (oembed.hasAttribute('data-media-file-json')) {
            const json = JSON.parse(oembed.getAttribute('data-media-file-json'))
            switch (json.type) {
                case 'document':
                    this._loadPdf(oembed, json)
                    break
                case 'video':
                    this._loadVideo(oembed, json)
                    break
                default:
                    break
            }
        }

        oembed.style.display = 'none'
    }

    _loadPdf(oembed, json) {
        const obj = document.createElement('object')
        obj.setAttribute('data', json.document[0])
        obj.setAttribute('type', 'application/pdf')
        obj.classList.add('pdf')
        obj.style.width = '100%'
        obj.style.height = '60vh'
        oembed.parentNode.appendChild(obj)
    }

    _loadVideo(oembed, json) {
        const video = document.createElement('video')
        for (let src of json.video) {
            const source = document.createElement('source')
            source.setAttribute('src', src)
            video.appendChild(source)
        }
        video.style.width = '100%'
        video.style.height = '100%'
        video.setAttribute('data-media-element-target', 'mediaElement')

        const wrapper = document.createElement('div')
        wrapper.setAttribute('data-controller', 'media-element')
        wrapper.setAttribute('data-media-element-dash-path-value', this.dashPathValue)
        wrapper.setAttribute('data-media-element-hls-path-value', this.hlsPathValue)
        wrapper.setAttribute('data-media-element-icon-sprite-path-value', this.iconSpritePathValue)
        wrapper.append(video)
        oembed.parentNode.appendChild(wrapper)
    }

    _loadYoutubeVimeo(oembed) {
        const video = document.createElement('video')
        video.setAttribute('src', oembed.getAttribute('url'))
        video.style.width = '100%'
        video.style.height = '100%'
        oembed.parentNode.appendChild(video)

        new MediaElementPlayer(video, {
            renderers: ['youtube_iframe', 'vimeo_iframe'],
            features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'],
            iconSprite: this.iconSpritePathValue,
            autoRewind: false,
            youtube: {
                nocookie: true
            }
        })
    }
}
