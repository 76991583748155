import {Controller} from '@hotwired/stimulus'
import {Collapse} from 'bootstrap'

export default class extends Controller {
    connect() {
        // TODO: Actually make this work. For some reasons this wont work and cannot trigger the element itself,
        //  only the collapse can be triggered. @see popover_controller.js
        new Collapse(this.element)
    }
}
